<template>
  <div class="home">
    <Header class="header-at-home" />
    <HeadSection class="head-at-home"/>
    <VariabilitySection class="variability-at-home" />
    <CompetencySection class="competency-at-home" />
    <ActivitiesSection class="activities-at-home" />
    <Footer class="footer-at-home"/>
  </div>
</template>

<script>
import HeadSection from '@/components/sections/HeadSection.vue';
import Header from '@/components/Header.vue';
import VariabilitySection from '@/components/sections/VariabilitySection.vue';
import CompetencySection from '@/components/sections/CompetencySection.vue';
import Footer from '@/components/Footer.vue';
import ActivitiesSection from '@/components/sections/ActivitiesSection.vue';

export default {
  name: 'HomeView',
  components: {
    ActivitiesSection,
    Footer,
    CompetencySection,
    VariabilitySection,
    HeadSection,
    Header,
  },
};
</script>

<style scoped>
  .header-at-home {
    padding-bottom: 100px;
    background-color: #efeff4;
  }
  .head-at-home {
    padding-bottom: 80px;
  }
  .variability-at-home {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .competency-at-home {
    padding-top: 90px;
    padding-bottom: 50px;
  }
  .activities-at-home {
    padding-top: 90px;
    padding-bottom: 50px;
  }
  .footer-at-home {
    padding: 110px 0 100px;
  }
</style>
