<template>
  <header class="footer">
    <div class="wrapper">
      <div class="footer-content">
        <a href="mailto:info@potoksystem.ru" class="footer-email">
          info@potoksystem.ru
        </a>
        <div class="footer-company">
          ООО «ПОТОК.СИСТЕМА»
          <p>
            Адрес: 115191, г. Москва, вн.тер.г. муниципальный округ Даниловский,
            пер. Холодильный, д. 3 к. 1 стр. 3, помещ. 8, ком. 5
          </p>
          <p>
            ОГРН 1227700895597 ИНН 9726032400, КПП 772601001
          </p>
          <p>Телефон: +7 (929) 911-93-05.</p>
          <p>
            Основной вид деятельности: Разработка компьютерного программного обеспечения (62.01)
          </p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
  .footer-content {
    display: flex;
    flex-direction: column;
  }
  .footer-telephone {
    font-size: 32px;
    text-decoration: none;
    color: #000;
  }
  .footer-email {
    text-decoration: none;
    transition: color .3s ease;
    color: #0732a2;
  }
  .footer-email:hover {
    color: #fc5544;
  }
  .footer-company {
    margin-top: 20px;
  }
  .footer-inn {
    color: #666;
  }
</style>
