<template>
  <section class="variability">
    <div class="wrapper">
      <h3 class="variability-title">
        Разработка ПО для автоматизации<br>
        нестандартных процессов
      </h3>

      <div class="variability-list">
        <div
          v-for="(item, i) in items"
          :key="i + item.icon"
          class="variability-item"
        >
          <div class="variability-icon-container">
            <font-awesome-icon
              :icon="item.icon"
              class="variability-icon"
              size="lg"
            />
          </div>

          <h4 class="variability-item-title">{{ item.title }}</h4>
          <p class="variability-item-text">{{ item.text }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VariabilitySection',
  data() {
    return {
      items: [
        {
          icon: 'fa-solid fa-swatchbook',
          title: 'Комплексные решения',
          text: 'Аналитика, разработка и внедрение программного обеспечение для автоматизации целых отделов и направлений бизнеса.',
        },
        {
          icon: 'fa-solid fa-puzzle-piece',
          title: 'Точечные решения',
          text: 'Разработка и внедрение точечных it-решений для автоматизации отдельных процессов с интеграцией в существующие системы.',
        },
        {
          icon: 'fa-solid fa-cart-shopping',
          title: 'Онлайн-продажи',
          text: 'Создание новых и модернизация существующих каналов онлайн-продаж услуг и продукции Вашей компании.',
        },
        {
          icon: 'fa-solid fa-people-group',
          title: 'Аутсорсинг разработки',
          text: 'Формирование и управление выделенной командой разработки от 2-х до нескольких десятков человек под Ваши проекты.',
        },
      ],
    };
  },
};
</script>

<style scoped>
  .variability {
    background-color: #f9f9fa;
  }
  .variability-title {
    font-size: 32px;
    margin-bottom: 60px;
  }
  .variability-list {
    display: flex;
  }
  .variability-item {
    padding: 0 12px 40px;
    width: 25%;
  }
  .variability-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
    background-color: #0732a2;
    border-radius: 50%;
  }
  .variability-icon {
    color: #ffffff;
  }
  .variability-item-title {
    margin-bottom: 12px;
    font-size: 20px;
  }
  .variability-item-text {
    color: #666;
  }
</style>
