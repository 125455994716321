/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/index.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faSwatchbook, faPuzzlePiece, faCartShopping, faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(faSwatchbook);
library.add(faPuzzlePiece);
library.add(faCartShopping);
library.add(faPeopleGroup);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
