<template>
  <section class="head">
    <div class="wrapper">
      <h1 class="head-title">
        Решения для бизнеса
      </h1>
      <p class="head-subtitle">
        Проектирование и разработка<br>
        программного обеспечения для бизнеса
      </p>

      <button class="head-btn">
        Начать проект
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeadSection',
};
</script>

<style scoped>
  .head {
    background-color: #efeff4;
  }
  .head-title {
    margin-bottom: 20px;
    font-size: 64px;
  }
  .head-subtitle {
    margin-bottom: 65px;
    color: #0732a2;
    font-size: 36px;
  }
  .head-btn {
    border: none;
    box-shadow: none;
    text-align: center;
    border-radius: 10px;
    transition-duration: .3s;

    min-height: 90px;
    padding: 30px 56px;
    font-size: 24px;

    color: #0732a2;
    background-color: #fff;
  }
  .head-btn:hover {
    background-color: #0732a2;
    color: #fff;
  }
</style>
