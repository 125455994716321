<template>
  <section class="activities">
    <div class="wrapper">
      <h3 class="activities-title">
        Направления деятельности
      </h3>
      <div class="activities-content">
        <ul class="list">
          <li
            v-for="(item, i) in items"
            :key="i + item"
            class="list-item"
          >
            <h4 class="list-item-text">
              {{ item.title }}
            </h4>
            <ul>
              <li
                v-for="(text, i) in item.textItems"
                :key="i"
                class="list-subitem"
              >
                - {{ text }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ActivitiesSection',
  data() {
    return {
      items: [
        {
          title: 'Аналитика IT-продуктов:',
          textItems: [
            'Анализ предметной области: определение целей, заинтересованных лиц, их влиянияна проект и возможности в системе, границ системы, бизнес требований.',
            'Формализация бизнес процессов: определение ролей и способов взаимодействия с системой.',
            'Моделирование предметной области: определение основных элементов, их свойств и взаимоотношений. Проектирование базы данных и функциональных требований на основе построенной модели.',
            'Формализация нефункциональных системных требований: к серверам и нагрузке, к тестированию, локализации, дизайну, пользователям и прочее.',
            'Описание функциональных требований и сценариев использования для каждой роли в системе. ',
          ],
        },
        {
          title: 'Проектирование программного обеспечения:',
          textItems: [
            'Разработка концепции будущей системы.',
            'Написание технического задания, с учетом необходимой IT-инфраструктуры или интеграции с элементами существующей IT-инфраструктуры.',
            'Разработка проектной документации: конкретные задания, стратегии и рабочие спецификации.',
            'Разработка высоко- (HLD) и низкоуровневого дизайна (LLD) внедряемого решения.',
            'Составление детальной инструкции и регламентов по использованию программного обеспечения после внедрения.',
          ],
        },
        {
          title: 'Разработка веб-сервисов и мобильных приложений:',
          textItems: [
            'Сбор требований к приложению.',
            'Предварительная оценка стоимости разработки.',
            'Проектирование прототипа.',
            'Составление технического задания.',
            'Разработка первого релиза продукта.',
            'Тестирование',
            'Публикация.',
            'Техническая поддержка.',
          ],
        },
        {
          title: 'Аутсорсинг разработки:',
          textItems: [
            'Анализ цифровой стратегии и технического задания.',
            'Разработка программного обеспечения.',
            'QA и тестирование.',
            'Внедрение программных решений.',
            'Поддержка пользователей.',
          ],
        },
        {
          title: 'Администрирование серверного программного обеспечения:',
          textItems: [
            'Администрирование программного обеспечения сервера.',
            'Мониторинг и резервное копирование.',
          ],
        },
        {
          title: 'Языки программирования, фреймворки, ПО:',
          textItems: [
            'Node.js, Koa, Ruby, Ruby оn Rails, JavaScript, Vue.js, Python, PostgreSQL, Redis, Kafka, 1C, HTML, CSS, K8s, Docker, GitLab, Prometheus, Grafana, Jenkins.',
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.activities {
  background-color: #f9f9fa;
}
.activities-title {
  font-size: 32px;
  margin-bottom: 50px;
}
.list-item {
  font-size: 20px;
  margin-bottom: 30px;
  color: #0732a2;
}
.list-subitem {
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 50px;
  color: #000000;
}
.list-item-text {
  margin-left: 20px;
  margin-bottom: 30px;
}
</style>
