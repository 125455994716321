<template>
  <header class="header">
    <div class="wrapper">
      <RouterLink to="/">
        <img
          class="header-logo"
          src="@/assets/logo.svg"
          alt="logo">
      </RouterLink>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped>
  .header {
    padding: 33px 0;
  }
  .header-logo {
    width: 175px;
    height: 38px;
  }
</style>
