<template>
  <section class="competency">
    <div class="wrapper">
      <h3 class="competency-title">
        Компетенции
      </h3>
      <div class="competency-content">
        <div class="competency-img-container">
          <img
            class="competency-img"
            src="@/assets/competency.png"
            alt="competency-img"
          >
        </div>

        <ul class="list">
          <li
            v-for="(item, i) in items"
            :key="i + item"
            class="list-item"
          >
            <div>—</div>
            <span class="list-item-text">
              {{ item }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CompetencySection',
  data() {
    return {
      items: [
        'Аналитика и проектирование IT-продуктов',
        'IT-продукты для бизнеса',
        'Онлайн-сервисы по индивидуальным требованиям',
        'Коммуникационные сервисы',
        'Отраслевые и профессиональные онлайн-сообщества',
        'Маркетплейсы и агрегаторы услуг',
        'SaaS-решения',
      ],
    };
  },
};
</script>

<style scoped>
.competency-title {
  font-size: 32px;
  margin-bottom: 50px;
  padding-right: 150px;
  text-align: center;
}
.competency-content {
  display: flex;
}
.competency-img-container {
  width: 30%;
  margin-top: 50px;
  margin-right: 50px;
}
.list-item {
  display: flex;
  font-size: 20px;
  margin-bottom: 30px;
  color: #0732a2;
}
.list-item-text {
  margin-left: 20px;
}
</style>
