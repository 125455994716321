import { render, staticRenderFns } from "./HeadSection.vue?vue&type=template&id=2260e425&scoped=true&"
import script from "./HeadSection.vue?vue&type=script&lang=js&"
export * from "./HeadSection.vue?vue&type=script&lang=js&"
import style0 from "./HeadSection.vue?vue&type=style&index=0&id=2260e425&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2260e425",
  null
  
)

export default component.exports